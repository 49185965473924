import React, {useState} from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import {Link} from 'react-router-dom';

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => {
        setNav(!nav)
    }

  return (
    <div className='w-full h-[90px] bg-black'>
      <div className='max-w-[1240px] mx-auto px-4 flex justify-between items-center h-full'>
        <div>
          <h1 className='text-[#6A8FD4]'><span className="text-[#ffff]">Crisis</span>Coins™</h1>
        </div>
        <div className='hidden md:flex'>
          <ul className='flex text-white items-center'>
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/services'>Services</Link></li>
            <li><Link to='/investors'>Investors</Link></li>
            <li><Link to='/about'>About</Link></li>
            <li><Link to='/contact'>Contact</Link></li>
            <a href="https://0x4a5.github.io/portfolio/">
            <button class="bg-slate-900 border-2 border-indigo-400/50 font-medium hover:opacity-100 hover:scale-110 m-8 px-4 py-2 rounded-md shadow-xl shadow-indigo-400/25 text-1xl text-[#D9D9D9] transition duration-300">Portfolio</button></a>
            </ul>
            </div>

        {/* Hamburger menu */}
           <div onClick={handleNav} className='block md:hidden'>
             {nav ? <AiOutlineClose size={30} className='text-white' /> : <AiOutlineMenu size={30} className='text-white' />}
          </div>  

        {/* Mobile Menu */}
        <div className={nav ? 'w-full bg-black text-white absolute top-[90px] left-0 flex justify-center text-center' : 
    'absolute left-[-100%]'}>
          <ul>
          <li><Link to='/services' className='text-xl'>Services</Link></li>
          <li><Link to='/investors' className='text-xl'>Investors</Link></li>
          <li><Link to='/portfolio' className='text-xl'>Portfolio</Link></li>
          <li><Link to='/about' className='text-xl'>About</Link></li>
          <li><Link to='/contact' className='text-xl'>Contact</Link></li>
          <Link to='/contact' className='text-xl'><button class="border-2 border-[#00d8ff] text-[#D9D9D9] px-4 py-2 m-8 rounded-md text-1xl font-medium hover:bg-blue-500 transition duration-300">Let's Do This!</button></Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;